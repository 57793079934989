<template>
  <div v-if="isMobile()">
    <feedback-header>
      <!-- form input -->
      <form class="form-input">
        <div class="form-textarea">
          <p class="text-input">
            1. Nama Kamu
          </p>
          <input
            type="text"
            class="form-text-input"
            placeholder="Masukan Nama"
            :value="data.name"
            :disabled="isLoadingPostFeedback"
            @input="e => changeField('name', e)"
          />
          <p v-show="isNameError" class="text-error">
            Mohon isi Nama Anda
          </p>
        </div>
        <div class="form-textarea">
          <p class="text-input">
            2. Email Kamu
          </p>
          <input
            type="text"
            class="form-text-input"
            placeholder="Masukan Email"
            :value="data.email"
            :disabled="isLoadingPostFeedback"
            @input="e => changeField('email', e)"
          />
          <p v-show="isEmailError" class="text-error">
            Mohon isi Email Anda
          </p>
          <p v-show="isFormatEmailError" class="text-error">
            Format Email Salah
          </p>
        </div>
      </form>

      <!-- button -->
      <div class="button-send-feedback">
        <button
          v-show="showPostFeedback"
          class="button is-danger is-fullwidth"
          @click="postFeedback()"
        >
          <p class="btn-title-oleholeh">
            Kirim Saran
          </p>
        </button>
        <button
          v-show="isLoadingPostFeedback"
          class="button is-danger is-fullwidth is-loading"
        />
      </div>
    </feedback-header>
    <!-- modal -->
    <modal-success v-if="modalOpen" width="100%" :use-icon-close="false">
      <template>
        <div class="modal-success-feedback">
          <img :src="'/images/checklist_green.png'" alt="" />
          <h1>Saran Terkirim</h1>
          <p>Terima kasih atas saranmu!</p>
          <button-modal text="Kembali Ke Homepage" @click="goToHome" />
        </div>
      </template>
    </modal-success>
  </div>
</template>

<script>
import axios from 'axios';
import MetaInfoUseCase from '@/app/metaApi/usecase/metaInfo.js';
import Modal from '@/components/new-modal/Modal.vue';
import FeedbackHeader from './component/feedback.vue';
import Button from '@/components/new-button/Button.vue';

export default {
  name: 'FeedbackPage2',
  components: {
    'feedback-header': FeedbackHeader,
    'modal-success': Modal,
    'button-modal': Button,
  },
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
  data() {
    return {
      modalOpen: false,
      data: {
        name: '',
        email: '',
      },
      isNameError: false,
      isEmailError: false,
      isFormatEmailError: false,
      isLoadingPostFeedback: false,
      showPostFeedback: true,
      metaInfo: '',
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    this.fetchMetaInfo();
  },
  methods: {
    fetchMetaInfo() {
      MetaInfoUseCase.getMeta('_oleholeh_feedback') // path tapi / dengan _ ex: /about jadi _about
        .then(resp => {
          if (!resp.err && resp.data) {
            this.metaInfo = resp.data;
          }
        })
        .catch(err => {});
    },
    isMobile() {
      if (this.isSsr()) {
        return false
      }
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      );
    },
    changeField(field, e) {
      if (field === 'name') {
        if (e.target.value.length > 0) {
          this.isNameError = false;
        } else {
          this.isNameError = true;
        }
        this.data.name = e.target.value;
      } else if (field === 'email') {
        if (e.target.value.length > 0) {
          this.isEmailError = false;
          const regex = new RegExp(
            /^([A-z0-9-.@]+)@([A-z0-9-.]+)\.([A-z]{2,5})$/i,
          );
          const valid = regex.test(e.target.value);
          if (valid) {
            this.isFormatEmailError = false;
          } else {
            this.isFormatEmailError = true;
          }
        } else {
          this.isEmailError = true;
          this.isFormatEmailError = false;
        }
        this.data.email = e.target.value;
      }
    },
    postFeedback() {
      const feedback_id = Number(this.$route.params.feedback_id);
      if (this.data.name.length === 0 && this.data.email.length === 0) {
        this.isNameError = true;
        this.isEmailError = true;
      } else if (this.data.name.length === 0) {
        this.isNameError = true;
      } else if (this.data.email.length === 0) {
        this.isEmailError = true;
      } else if (!this.isFormatEmailError) {
        this.isLoadingPostFeedback = true;
        const api = process.env.VUE_APP_DOMAIN_URL;
        this.showPostFeedback = false;
        this.isLoadingPostFeedback = true;
        axios
          .put(
            `${api}/v1/shop/feedback/${feedback_id}`,
            JSON.stringify(this.data),
            {
              headers: {
                Authorization: process.env.VUE_APP_TOKEN_URL,
                'Content-Type': 'application/json',
              },
            },
          )
          .then(res => {
            if (res.status === 200) {
              this.isLoadingPostFeedback = false;
              this.openModal();
            }
          })
          .catch(err => {
            this.isLoadingPostFeedback = false;
            if (err.message === 'Network Error') {
              alert('Network error, please check your connection');
            }
          });
      }
    },
    openModal() {
      this.modalOpen = !this.modalOpen;
    },
    closeModal() {
      this.modalOpen = !this.modalOpen;
      this.$router.push('/oleholeh');
    },
    goToHome() {
      this.$router.push('/');
    },
  },
};
</script>

<style scoped lang="scss">
@import './feedbackpage2.css';
</style>
