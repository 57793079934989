<template>
  <div v-if="isMobile()">
    <feedback-header>
      <!-- form input -->
      <form class="form-input-content">
        <div class="form-textarea">
          <p class="title-text-area">
            1. BisaBelanja apa yang kamu inginkan ?
            <br />Kamu boleh sebut detail merknya!
          </p>
          <textarea
            class="text-area"
            rows="5"
            placeholder="Contoh: Sambel Botol Bu Rudy, Batik Pekalongan, Kaos Joger"
            :value="OLEH_OLEH.answer_one"
            :disabled="isLoadingPostFeedback"
            @input="e => changeField('answer_one', e)"
          />
          <p v-show="isAnswerOneError" class="text-error">
            Mohon isi Jawaban Anda
          </p>
        </div>
        <div class="form-textarea">
          <p class="title-text-area">
            2. Dari kota mana BisaBelanja tersebut bisa didapatkan?
          </p>
          <textarea
            class="text-area"
            rows="5"
            placeholder="Contoh: Jakarta, Bandung"
            :value="OLEH_OLEH.answer_two"
            :disabled="isLoadingPostFeedback"
            @input="e => changeField('answer_two', e)"
          />
          <p v-show="isAnswerTwoError" class="text-error">
            Mohon isi Jawaban Anda
          </p>
        </div>
        <div class="form-textarea">
          <p class="title-text-area">
            3. Ada saran lain yang mau kamu sampaikan?
          </p>
          <textarea
            class="text-area"
            rows="5"
            placeholder="Contoh: Gambar produk kurang jelas, Ingin ada pengiriman ke Balikpapan, dll."
            :value="OLEH_OLEH.answer_three"
            :disabled="isLoadingPostFeedback"
            @input="e => changeField('answer_three', e)"
          />
        </div>
      </form>

      <!-- button -->
      <div class="button-next-feedback">
        <button
          class="button is-danger is-fullwidth"
          :disabled="isLoadingPostFeedback"
          @click="postFeedback()"
        >
          <p class="btn-title-oleholeh">
            Lanjutkan
          </p>
        </button>
      </div>
    </feedback-header>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import MetaInfoUseCase from '@/app/metaApi/usecase/metaInfo.js';
import FeedbackHeader from './component/feedback.vue';

export default {
  name: 'FeedbackPage',
  components: {
    'feedback-header': FeedbackHeader,
  },
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
  data() {
    return {
      isAnswerOneError: false,
      isAnswerTwoError: false,
      isLoadingPostFeedback: false,
      metaInfo: '',
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    this.fetchMetaInfo();
  },
  computed: {
    ...mapGetters(['OLEH_OLEH']),
  },
  methods: {
    fetchMetaInfo() {
      MetaInfoUseCase.getMeta('_oleholeh_feedback') // path tapi / dengan _ ex: /about jadi _about
        .then(resp => {
          if (!resp.err && resp.data) {
            this.metaInfo = resp.data;
          }
        })
        .catch(err => {});
    },
    ...mapActions(['setOlehOleh']),
    isMobile() {
      if (this.isSsr()) {
        return false;
      }
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      );
    },
    changeField(field, e) {
      if (field === 'answer_one') {
        if (e.target.value.length > 0) {
          this.isAnswerOneError = false;
        } else {
          this.isAnswerOneError = true;
        }
        this.setOlehOleh({
          answer_one: e.target.value,
          answer_two: this.OLEH_OLEH.answer_two,
          answer_three: this.OLEH_OLEH.answer_three,
        });
      } else if (field === 'answer_two') {
        if (e.target.value.length > 0) {
          this.isAnswerTwoError = false;
        } else {
          this.isAnswerTwoError = true;
        }
        this.setOlehOleh({
          answer_one: this.OLEH_OLEH.answer_one,
          answer_two: e.target.value,
          answer_three: this.OLEH_OLEH.answer_three,
        });
      } else if (field === 'answer_three') {
        this.setOlehOleh({
          answer_one: this.OLEH_OLEH.answer_one,
          answer_two: this.OLEH_OLEH.answer_two,
          answer_three: e.target.value,
        });
      }
    },
    postFeedback() {
      if (
        this.OLEH_OLEH.answer_one.length === 0 &&
        this.OLEH_OLEH.answer_two.length === 0
      ) {
        this.isAnswerOneError = true;
        this.isAnswerTwoError = true;
      } else if (this.OLEH_OLEH.answer_one.length === 0) {
        this.isAnswerOneError = true;
      } else if (this.OLEH_OLEH.answer_two.length === 0) {
        this.isAnswerTwoError = true;
      } else {
        this.isLoadingPostFeedback = true;
        const api = process.env.VUE_APP_DOMAIN_URL;
        axios
          .post(`${api}/v1/shop/feedback`, JSON.stringify(this.OLEH_OLEH), {
            headers: {
              Authorization: process.env.VUE_APP_TOKEN_URL,
              'Content-Type': 'application/json',
            },
          })
          .then(res => {
            this.isLoadingPostFeedback = false;
            this.$router.push(`/oleholeh/feedback/${res.data.feedback_id}`);
          })
          .catch(err => {
            this.isLoadingPostFeedback = false;
            if (err.message === 'Network Error') {
              alert('Network error, please check your connection');
            }
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import './feedbackpage.css';
</style>
